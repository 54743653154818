// Some translation keys have plurity postfixes like:
// "js:cart:add_to_cart_button:with_quantity": {
//   "one": "Add 1 Item to Cart",
//   "other": "Add %{count} Items to Cart"
// },
// We support it by passing the count as one of the values and using
// the intl.formatPlural function to get the proper key.
export const getMessageId = (id, values, intl) => values?.count !== undefined
  ? `${id}.${intl.formatPlural(values.count)}`
  : id
