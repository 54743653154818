// Accessibe is an accessibility audit tool which scans for + automatically
// corrects accessibility issues. At time of writing, it cannot be used with
// next/script, which Accessibe support suggests may be adding some attributes
// which break the script
export const accessibeScript = `
  (function(){
      var s    = document.createElement('script');
      var h    = document.querySelector('head') || document.body;
      s.src    = 'https://acsbapp.com/apps/app/dist/js/app.js';
      s.async  = true;
      s.onload = function(){
          acsbJS.init({
              statementLink    : '',
              footerHtml       : '',
              hideMobile       : false,
              hideTrigger      : false,
              disableBgProcess : false,
              language         : 'en',
              position         : 'right',
              leadColor        : '#146FF8',
              triggerColor     : '#146FF8',
              triggerRadius    : '50%',
              triggerPositionX : 'right',
              triggerPositionY : 'bottom',
              triggerIcon      : 'people',
              triggerSize      : 'bottom',
              triggerOffsetX   : 20,
              triggerOffsetY   : 20,
              mobile           : {
                  triggerSize      : 'small',
                  triggerPositionX : 'right',
                  triggerPositionY : 'bottom',
                  triggerOffsetX   : 20,
                  triggerOffsetY   : 20,
                  triggerRadius    : '20'
              }
          });
      };
      h.appendChild(s);
  })();
`
