import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import cookie from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

const { cookies } = viewlioConfig

export const getDomain = (hostname) => {
  const parts = hostname.split('.')

  return parts.slice(parts.length - 2).join('.')
}

export const setInitialSegmentCookie = () => {
  const value = cookie.get(cookies.juulAnonymousId)

  if (
    (typeof window === 'undefined') ||
    (typeof value === 'string' && value.length > 0)
  ) {
    return
  }

  cookie.set(
    cookies.juulAnonymousId,
    JSON.stringify(uuidv4()),
    {
      domain: `.${getDomain(window.location.hostname)}`,
      expires: viewlioConfig.cookieTTL.anonymousId,
      secure: window.location.protocol === 'https:',
    },
  )
}
