import {
  DataResponse,
  ResponseV4,
  ResponseV4Array,
  ParsedV4Response,
  ConstructRelationshipsProps,
} from '@viewlio/types/src/api/juulio'

import { camelizeKeys } from 'utils/caseConversion'

const constructRelationships = ({
  included,
  relationships,
}: ConstructRelationshipsProps): Record<string, any> =>
  Object.keys(relationships)
    .reduce((accumulator, relationshipName) => {
      const relationship = relationships[relationshipName]

      if (!included) {
        return {
          [relationshipName]: relationship['data'],
          ...accumulator,
        }
      }

      const relationshipId = relationship['data']['id']
      const relationshipType = relationship['data']['type']
      const matchedRelationship = included.find(
        x => x.id === relationshipId && x.type === relationshipType,
      )

      if (matchedRelationship) {
        return {
          [relationshipName]: {
            id: matchedRelationship.id,
            type: matchedRelationship.type,
            ...matchedRelationship.attributes,
          },
          ...accumulator,
        }
      }

      return {
        [relationshipName]: {
          ...relationship['data'],
        },
        ...accumulator,
      }
    }, {})

export const parseV4Response = <T,>(
  response: ResponseV4<T> | ResponseV4Array<T>,
): ParsedV4Response<T> | ParsedV4Response<T>[] | null => {
  const {
    data,
    included,
    links,
  } = camelizeKeys(response)

  if (!data) return null

  if (Array.isArray(data)) return data.map(entry => ({
    ...entry.attributes,
    ...constructRelationships({ included, relationships: entry.relationships }),
    links,
  }))

  const {
    relationships,
    attributes,
  } = data as DataResponse<T>

  const matchedObject = constructRelationships({ included, relationships })

  return {
    ...attributes,
    ...matchedObject,
    links,
  }
}
