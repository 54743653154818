import { ReactNode } from 'react'

import { FlashMessage } from '@viewlio/types/src'
import { create } from 'zustand'

export enum SnackbarPosition {
  // Because our added to cart notification uses a different location
  // on mobile and desktop, we created a dedicated position for it.
  AddedToCart = 'added-to-cart',
  Bottom = 'bottom',
  Top = 'top'
}

export type SnackbarItem = {
  children: JSX.Element
  duration: number
  id?: string
  onExit?(): void
  position: SnackbarPosition
  theme?: 'light' | 'dark'
}

export type UiStore = {
  confirmationNotification?: { content?: ReactNode }
  flashMessages: Array<FlashMessage & { id: string }>
  snackbar: {
    addedToCartCount: number
    items: SnackbarItem[]
    theme: 'light' | 'dark'
  }
}

export const uiStoreInitialState = (): UiStore => ({
  confirmationNotification: null,
  flashMessages: [],
  snackbar: {
    addedToCartCount: 0,
    items: [],
    theme: 'light',
  },
})

export const useUiStore = create(uiStoreInitialState)
