import { Consent, ConsentCategory } from '../cookiebot'

// The whole list of supported Segment destinations.
export enum Integrations {
  FullStory = 'Fullstory (Actions)',
  GoogleAnalytics = 'Google Analytics',
  GoogleAnalytics4 = 'Google Analytics 4 Web',
  GoogleTagManager = 'Google Tag Manager',
  Iterable = 'Iterable',
  S3 = 'Amazon S3',
  Salesforce = 'Salesforce',
  Segment = 'Segment.io',
  Webhooks = 'Webhooks',
}

// The type of the integrations config object that we pass to Segment.
export type IntegrationsConfig = {
  All: boolean
} & Partial<Record<Integrations, boolean>>

// This is a mapping between Segment destinations and the
// Cookiebot consent category to which they belong. Using this mapping
// we can compute which destinations should be turned on/off based on
// the user's preferences.
const integrationsByCategory: Record<ConsentCategory, Integrations[]> = {
  [ConsentCategory.Marketing]: [
    Integrations.S3,
    Integrations.Salesforce,
  ],
  [ConsentCategory.Necessary]: [
    Integrations.FullStory,
    Integrations.GoogleTagManager,
    Integrations.Iterable,
    Integrations.Segment,
  ],
  [ConsentCategory.Statistics]: [
    Integrations.GoogleAnalytics,
    Integrations.GoogleAnalytics4,
    Integrations.Webhooks,
  ],
}

export const integrationsByConsent = (consent: Consent): IntegrationsConfig => {
  const consentCategories = Object.values(ConsentCategory)
  const integrations = { All: false }

  consentCategories.forEach(category => {
    integrationsByCategory[category]?.forEach(integration => {
      integrations[integration] = Boolean(consent[category]) || false
    })
  })

  return integrations
}
