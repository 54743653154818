import { ReactNode } from 'react'

import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import { FlashMessage } from '@viewlio/types/src'
import { v4 as uuidv4 } from 'uuid'

import { getSplitTreatment } from 'lib/juulio/api'

import { useUiStore, SnackbarItem } from './uiStore'

const { getState, setState } = useUiStore

export const removeFlashMessage = (id: string) =>
  setState(({ flashMessages }) => ({
    flashMessages: flashMessages.filter(message => message.id !== id),
  }))

export const addFlashMessage = (message: FlashMessage) => {
  const id = uuidv4()
  const timeout = message.timeout || viewlioConfig.timers.flashMessageTimeoutMs

  setState(({ flashMessages }) => ({
    flashMessages: flashMessages.concat({
      ...message,
      id,
      timeout,
    }),
  }))

  setTimeout(() => removeFlashMessage(id), timeout)
}

export const flushFlashMessages = () => {
  setState({
    flashMessages: [],
  })
}

export const showConfirmationNotification = (content: ReactNode = null) => {
  setState({
    confirmationNotification: {
      content,
    },
  })

  setTimeout(() => setState({
    confirmationNotification: null,
  }), viewlioConfig.timers.notificationDurationMs)
}

export const removeSnackbarItem = (id: string) => {
  const { snackbar } = getState()

  setState({
    snackbar: {
      ...snackbar,
      items: snackbar.items.filter(item => item.id !== id),
    },
  })
}

export const setSnackbarAddedToCartCount = (to: number) =>
  setState({ snackbar: { ...getState().snackbar, addedToCartCount: to } })

export const updateSnackbarItem = (id: string, newProps: SnackbarItem) => {
  const { snackbar } = getState()

  setState({
    snackbar: {
      ...snackbar,
      items: snackbar.items.map((snackbarItem) =>
        snackbarItem.id === id
          ? { ...snackbarItem, ...newProps }
          : snackbarItem,
      ),
    },
  })
}

export const addSnackbarItem = (item: SnackbarItem) => {
  const { snackbar } = getState()
  const id = item.id || `snackbar-item-${uuidv4()}`

  if (snackbar.items.find(item => item.id === id)) {
    updateSnackbarItem(id, item)
  } else {
    setState({
      snackbar: {
        ...snackbar,
        items: [
          ...snackbar.items,
          { ...item, id },
        ],
      },
    })
  }
}

export const fetchSnackbarColorTreatment = async () => {
  const { snackbar } = getState()
  try {
    const {
      features: {
        acqGlobalInvertCartSnackbar,
      },
    } = await getSplitTreatment('acq-global-invert-cart-snackbar')
    const theme = acqGlobalInvertCartSnackbar === 'on' ? 'dark' : 'light'
    setState({
      snackbar: {
        ...snackbar,
        theme,
      },
    })
  } catch {
    setState({
      snackbar: {
        ...snackbar,
        theme: 'light',
      },
    })
  }
}
