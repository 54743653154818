const CLAIM_WARRANTY_PATH = '/claim-warranty'
const ACCOUNT_PATH = '/account'
const AGE_VERIFICATION_PATH = `${ACCOUNT_PATH}/age-verification`
const AGE_VERIFICATION_NETVERIFY_PATH = '/age_verification/netverify'
const PHONE_VERIFICATION_PATH = '/authentication/phone-verification'

const accountPreSignin = {
  accountRecovery: [
    '/account/recovery',
  ],
  passwordReset: [
    '/password',
    '/password/new',
  ],
  signIn: [
    '/signin',
    '/sso/signin',
  ],
}

const accountPostSignin = {
  ageVerification: [
    AGE_VERIFICATION_PATH,
    `${AGE_VERIFICATION_PATH}/success`,
    `${AGE_VERIFICATION_NETVERIFY_PATH}/scan`,
    `${AGE_VERIFICATION_NETVERIFY_PATH}/upload/success`,
    `${AGE_VERIFICATION_NETVERIFY_PATH}/upload/error`,
  ],
  dashboard: [
    ACCOUNT_PATH,
    `${ACCOUNT_PATH}/membership`,
    `${ACCOUNT_PATH}/product_registration`,
    `${ACCOUNT_PATH}/profile`,
    `${ACCOUNT_PATH}/orders`,
    `${ACCOUNT_PATH}/orders/:orderNumber`,
    `${ACCOUNT_PATH}/membership`,
  ],
  phoneVerification: [
    PHONE_VERIFICATION_PATH,
    `${PHONE_VERIFICATION_PATH}/success`,
  ],
  signOut: [
    '/signout',
  ],
}

const warranty: readonly string[] = [
  CLAIM_WARRANTY_PATH,
  `${CLAIM_WARRANTY_PATH}/start`,
  `${CLAIM_WARRANTY_PATH}/introduction`,
  `${CLAIM_WARRANTY_PATH}/start-international`,
] as const

// All path patterns are in the format specified by the 'path-to-regexp' package
export const pathPatternsByRole = {
  accountPostSignin: Object.values(accountPostSignin).flat(),
  accountPreSignin: Object.values(accountPreSignin).flat(),
  signout: accountPostSignin.signOut,
  warranty: [
    ...Object.values({
      ...accountPreSignin,
      ...accountPostSignin,
    }).flat(),
    ...warranty,
  ],
}
