import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import { AgreeConfig } from '@viewlio/types/src/AgeGate'
import cookie from 'js-cookie'
import { useRouter } from 'next/router'

import { useGlobalStore } from 'stores'
import { isPassingWarrantyAgeGate } from 'utils/complianceUI/useVerifyAgeGate'
import { pathPatternMatcher } from 'utils/urls/pathPatternMatcher'

export const shouldRedirectToLocator = (): boolean => {
  const {
    juulioStore: {
      ageGate,
    },
  } = useGlobalStore.getState()

  const { pathname } = useRouter()

  const shouldRouteToLocatorForBlockedStates =
    (ageGate.body.agree as AgreeConfig)?.routeToLocatorForBlockedStates

  const isBlockedState =
    ageGate.body.blockedStates.indexOf(
      cookie.get(viewlioConfig.cookies.stateAbbr),
    ) > -1

  if (
    pathPatternMatcher([
      '/age-gate',
      '/locator',
      '/support',
      ...viewlioConfig.ageGate.disabledPaths,
    ]).any(pathname)
    || isPassingWarrantyAgeGate(pathname)
  ) {
    return false
  }

  return isBlockedState && shouldRouteToLocatorForBlockedStates
}
