import React, { useMemo, FC, PropsWithChildren } from 'react'

import { ViewlioAppProps } from '../pages/_app'

import { useGlobalStore } from './globalStore'
import type { GlobalStore } from './globalStore'
import { selectCurrentProductCatalogue } from './globalStore.selectors'

export { useGlobalStore, selectCurrentProductCatalogue }
export { useStoreLocatorStore } from './storeLocatorStore'
export { useAutoshipBuilderStore, useAutoshipEditorStore } from './autoshipStore'
export { useCartStore } from './cartStore'
export { useUiStore } from './uiStore'
export { useAgeGateStatusStore } from './ageGateStatusStore'
export { useAccountStore } from './accountStore'
export { useAgeVerificationStore } from './ageVerificationStore'
export { useUserDeliverabilityStore } from './userDeliverabilityStore'

export type CreateStoresConfig = {
  globalStore: GlobalStore
}

export const initialStoreData = (createStoresConfig: CreateStoresConfig) => ({
  __viewlioInitialStoreData: createStoresConfig,
})

export const AppWrapper: FC<PropsWithChildren<ViewlioAppProps>> = ({
  children,
  pageProps,
}) => {

  const { __viewlioInitialStoreData }
    : { __viewlioInitialStoreData: CreateStoresConfig }
    = pageProps

  useMemo(() => {
    if (__viewlioInitialStoreData?.globalStore) {
      useGlobalStore.setState({ ...__viewlioInitialStoreData.globalStore })
    }
  }, [JSON.stringify(__viewlioInitialStoreData)])

  return <>{children}</>
}
