import React, { FC } from 'react'

import dynamic from 'next/dynamic'

import { useGlobalStore } from 'stores'

const SlideInWidget = dynamic(
  () => import('components/contentful/SlideInWidget').then((mod) => mod.SlideInWidget), {
    ssr: false,
  })
const PrimaryNotificationCornerBox = dynamic(
  () => import('components/contentful/PrimaryNotificationCornerBox').then((mod) => mod.PrimaryNotificationCornerBox), {
    ssr: false,
  })

export const PersonalizedNotifications: FC = () => {
  const {
    personalizedContent: {
      cornerBoxNotification,
      slideInWidget,
    },
  } = useGlobalStore()

  return (
    <>
      {slideInWidget && <SlideInWidget contentfulData={slideInWidget} />}
      {cornerBoxNotification &&
        <PrimaryNotificationCornerBox contentfulData={cornerBoxNotification} />}
    </>
  )
}
