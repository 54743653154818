import dayjs from 'dayjs';
import range from 'lodash/range';

import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

import 'dayjs/locale/en';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/fr';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/it';
import 'dayjs/locale/ru';
import 'dayjs/locale/uk';

const mappedLocalePlugin = (option, dayJsInstance, dayJsClass) => {
  const findCorrectLocale = locale => {
    if (dayJsClass.Ls[locale]) {
      return locale;
    }

    const lowerCaseLocale = locale.toLowerCase();
    if (dayJsClass.Ls[lowerCaseLocale]) {
      return lowerCaseLocale;
    }

    const chunks = locale.split('-');
    if (dayJsClass.Ls[chunks[0]]) {
      return chunks[0];
    }

    return 'en';
  };

  const getLocaleDateFormat = (locale, format) => {
    const correctedLocale = findCorrectLocale(locale);
    return dayJsClass.Ls[correctedLocale].formats[format];
  };

  const getShortDays = () =>
    range(7).map(dayIndex =>
      dayjs()
        .day(dayIndex)
        .format('ddd')
    );

  const getLongDays = () =>
    range(7).map(dayIndex =>
      dayjs()
        .day(dayIndex)
        .format('dddd')
    );

  const getShortMonths = () => {
    const day = dayjs();
    return range(12).map(monthIndex => day.month(monthIndex).format('MMM'));
  };

  const oldClassLocale = dayJsClass.locale;
  dayJsClass.locale = (locale, object) => {
    if (locale) {
      locale = findCorrectLocale(locale);
    }
    return oldClassLocale(locale, object);
  };

  const oldInstanceLocale = dayJsInstance.locale;
  dayJsInstance.locale = (locale, object) => {
    if (locale) {
      locale = findCorrectLocale(locale);
    }
    return oldInstanceLocale.call(this, locale, object);
  };

  dayJsClass.findCorrectLocale = findCorrectLocale;
  dayJsClass.getLocaleDateFormat = getLocaleDateFormat;
  dayJsClass.getShortDays = getShortDays;
  dayJsClass.getLongDays = getLongDays;
  dayJsClass.getShortMonths = getShortMonths;
};

dayjs.extend(mappedLocalePlugin);

// eslint-disable-next-line import/no-default-export
export default dayjs;
