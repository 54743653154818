import React, { FC } from 'react'

import cx from 'classnames'
import {
  useIntl,
  FormattedMessage,
} from 'react-intl'

import { getMessageId } from 'utils/translations/getMessageId'

import styles from './TranslatedMessage.module.scss'

type Props = {
    className?: string
    id: string
    textComponent?: React.ElementType
    values?: Record<string, unknown>
}

const translationIsMissing = (key, value) => key === value

export const TranslatedMessage: FC<Props> = ({
  className,
  id,
  textComponent,
  values,
}) => {
  const intl = useIntl()
  const messageId = getMessageId(id, values, intl)

  const renderMessageContent = (message) => {
    const messageIdWithPrefix = `js.${messageId}`

    const translationMissing = translationIsMissing(message, messageId)

    const messageClasses = cx(
      className,
      {
        [styles.missing]: translationMissing,
      },
    )

    const Component = textComponent || 'span'

    return (
      <Component
        className={messageClasses}
        data-i18n-key={messageIdWithPrefix}
      >
        {message}
      </Component>
    )
  }

  return (
    <FormattedMessage id={messageId} values={values}>
      {renderMessageContent}
    </FormattedMessage>
  )
}
