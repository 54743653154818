import React, { FC, PropsWithChildren } from 'react'

import { BugsnagContext } from '@viewlio/juulio-bridge/src/common/contexts/BugsnagContext'

import { bugsnagClient } from 'lib/bugsnag'

export const BugsnagContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const bugsnagContextValue = {
    bugsnagClient,
  }

  return (
    <BugsnagContext.Provider value={bugsnagContextValue}>
      {children}
    </BugsnagContext.Provider>
  )
}
