import { FlattenedEntry } from '@viewlio/types/src/contentful'
import { Asset, Entry as ContentfulEntry } from 'contentful'

export type Entry<T> = Omit<ContentfulEntry<T>, 'update'>

export type Value = string | Asset | Entry<any>[] | Entry<any>

const formatFields = (fields: Entry<any>['fields']) =>
  Object.entries(fields).map(([key, value]: [string, Value]) => ({
    [key]: formatValue(value),
  }))

export const formatAsset = ({ sys: { id }, fields: { file, ...fields } }) => ({
  id,
  ...fields,
  ...file,
})

const formatValue = (value: Value) => {
  if (typeof value === 'string') {
    return value
  }

  if (Array.isArray(value)) {
    return value.map(formatValue)
  }

  if (value?.fields?.file) {
    return formatAsset(value)
  }

  if (value?.fields) {
    return formatEntry(value)
  }

  return value
}

/**
 * - Remove extra Contentful metadata like 'sys' and 'file'.
 * - flatten the fields so that the entries are easier to use in our components.
 *   For example, `{ sys: { id: 'abc' }, fields: { key: 'value' } }` becomes
 *   `{ id: 'abc', key: 'value' }`.
 * - Camelcase all the keys.
 * @param entry A Contentful entry
 */
export const formatEntry = <T>(entry: Entry<T>): FlattenedEntry<T> => {
  if (!entry) {
    return null
  }

  const flattenedEntry = {
    contentType: entry.sys?.contentType?.sys?.id,
    id: entry.sys.id,
    ...Object.assign({}, ...formatFields(entry.fields)),
  } as Record<string, unknown>

  return flattenedEntry as FlattenedEntry<T>
}
