import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'

import { getStoreByLocale } from '../locales/getStoreByLocale'

import { removeTrailingSlash } from './removeTrailingSlash'

const isString = (arg: any): arg is string =>
  typeof arg === 'string'

export const getViewlioPath = (
  locale: string | undefined,
  url: string | undefined,
): string | null => {
  let modifiedUrl = url?.replace(/^\/home$/, '/')

  if (
    !isString(modifiedUrl) ||
    !isString(locale) ||
    modifiedUrl?.startsWith('http')
  ) {
    return null
  }

  const store = getStoreByLocale(locale)

  if (!store) {
    return null
  }

  const pathParamsRequired = viewlioConfig.stores[store as string].locales.length > 1 ? `/${locale}` : ''

  if (!modifiedUrl.startsWith(pathParamsRequired)) {
    modifiedUrl = `${pathParamsRequired}${modifiedUrl}`
  }

  return removeTrailingSlash(modifiedUrl)
}
