import React, { FC, PropsWithChildren } from 'react'

import { BugsnagContextProvider } from './BugsnagContextProvider'

const providers = [BugsnagContextProvider]

export const AppContextProvider: FC<PropsWithChildren> = ({ children }) => {
  let element = <>{children}</>

  for (const Provider of providers) {
    element = <Provider>{element}</Provider>
  }

  return element
}
