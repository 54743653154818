import { useEffect, useState } from 'react'

import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import cookie from 'js-cookie'
import { useRouter } from 'next/router'
import { v4 as uuidv4 } from 'uuid'

import { datadogLogger } from 'lib/datadog'
import { useAgeGateStatusStore, useGlobalStore } from 'stores'
import { pathPatternMatcher } from 'utils/urls/pathPatternMatcher'

const { cookies } = viewlioConfig

// Because this fallback is ONLY for REGULAR Age Gate
// We cannot do a full validity check against all age gate cookies
// If they have the warranty age gate cookie, we should not show the
// Regular Age Gate
export const isPassingWarrantyAgeGate = (path: string): boolean =>
  Boolean(cookie.get(cookies.warrantyAgeGate))
  && pathPatternMatcher(
    viewlioConfig.ageGate.warrantyPathPatterns,
  ).any(path)

const ageGateFallback = (
  asPath: string,
  bugsnagMetadata: Record<string, string | boolean>,
): void => {
  const fallbackId = uuidv4()

  // This block shouldn't run. If it does, it means age-gate redirects are not
  // working for all cases. Looking into the remaining cases it appears they are
  // almost all edge-cases, hence the logger level is 'info' as opposed to error
  datadogLogger.info('Fallback client-side age-gate redirect happening', {
    ...bugsnagMetadata,
    fallbackId,
  })

  const ageGateParams = new URLSearchParams()
  ageGateParams.set('redirect', asPath)
  ageGateParams.set('fallbackId', fallbackId)

  setTimeout(() => {
    window.location.assign(`/age-gate?${ageGateParams}`)
    // Allow time for Bugsnag request to resolve
  }, 400)
}

export const useVerifyAgeGate = (): boolean => {
  const [shouldRedirect, setShouldRedirect] = useState(true)
  const {
    ageGate: ageGateCookie,
    isAuthorizedBot,
    stateAbbr,
    userDeliverability,
  } = cookies
  const {
    asPath,
    isReady,
    pathname,
  } = useRouter()

  const {
    juulioStore: {
      ageGate,
      isBehindSignIn,
      forceSigninWhitelistedPaths,
    },
    shopper: {
      signedIn,
    },
  } = useGlobalStore()
  const { isAgeGateVerified } = useAgeGateStatusStore()

  const stateRequired = ageGate?.body?.selector && !cookie.get(stateAbbr)

  const passesAgeGate = Boolean(cookie.get(ageGateCookie) && !stateRequired)

  const passesWarrantyAgeGate = isPassingWarrantyAgeGate(asPath)

  const isOauthPermittedPath = pathPatternMatcher(
    viewlioConfig.ageGate.oauthPermittedPaths,
  ).any(pathname)

  const bugsnagMetadata = {
    ageGateCookie: Boolean(cookie.get(ageGateCookie)),
    ageGateSelector: Boolean(ageGate?.body?.selector),
    enablePartialDelivery: Boolean(ageGate.body?.enablePartialDelivery),
    isAuthorizedBot: Boolean(cookie.get(isAuthorizedBot)),
    passesAgeGate,
    passesWarrantyAgeGate,
    stateAbbr: Boolean(cookie.get(stateAbbr)),
    stateRequired: Boolean(stateRequired),
    userDeliverability: Boolean(cookie.get(userDeliverability)),
  }

  const redirectExceptions = [
    // currently on an age-gate disabled path
    pathPatternMatcher(viewlioConfig.ageGate.disabledPaths)
      .any(asPath.replace(/\?.*$/, '')),
    // sign-in should be outside age-gate
    isBehindSignIn !== undefined &&
      isBehindSignIn &&
      (signedIn ||
        forceSigninWhitelistedPaths.includes(pathname) ||
        isOauthPermittedPath),
    // oauth users should be able to access certain paths
    isOauthPermittedPath && cookie.get(viewlioConfig.cookies.fromOauth),
    // valid human or an authorized bot
    passesAgeGate || passesWarrantyAgeGate || cookie.get(isAuthorizedBot),
    // if no ageGate body, no redirect
    !(ageGate?.body),
  ].some(Boolean)

  useEffect(() => {
    if (redirectExceptions || !isReady) {
      setShouldRedirect(false)
    } else {
      ageGateFallback(
        asPath,
        bugsnagMetadata,
      )
    }
  }, [
    forceSigninWhitelistedPaths,
    isBehindSignIn,
    isAgeGateVerified,
    isReady,
    signedIn,
    shouldRedirect,
  ])

  return shouldRedirect
}
