import { NotifiableError } from '@bugsnag/js/types'
import isString from 'lodash/isString'

export const getBugsnagError = (error: NotifiableError | Response) => {
  if (error instanceof Error) {
    return error
  }

  if (isString(error)) {
    return error
  }

  if ('errorClass' in error && 'errorMessage' in error) {
    return error
  }

  if ('name' in error && 'message' in error) {
    return error
  }

  return JSON.stringify(error)
}
