/* eslint-disable viewlio/no-a-component */
import React from 'react'

import { useGlobalStore } from 'stores'

import styles from './ImpersonationNotice.module.scss'

export const ImpersonationNotice: React.FC = () => {
  const { shopper } = useGlobalStore()

  if (!shopper.impersonating) {
    return null
  }

  return (
    <div className={styles.container}>
      <a href= '/admin/users/end_impersonate'>
        You are logged in as: {shopper.user.email} [Back to admin]
      </a>
    </div>
  )
}
