import LocalizedDayjs from '@viewlio/juulio-bridge/src/common/localized_dayjs';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import getNodeEnv from './utils/getNodeEnv';
import isString from 'lodash/isString';
import trim from 'lodash/trim';
import IntlMessageFormat from 'intl-messageformat';

let isScrollLocked = false;

export const toggleScrollLock = function(
  replacementSelector,
  shouldLock = !isScrollLocked
) {
  // accepts a DOM node or a selector to be used
  const scrollTarget = replacementSelector.nodeType
    ? replacementSelector
    : typeof document !== 'undefined' &&
      document.querySelectorAll(replacementSelector)[0];
  if (scrollTarget) {
    if (shouldLock) {
      disableBodyScroll(scrollTarget);
    } else {
      enableBodyScroll(scrollTarget);
      // body-scroll-lock will set overflow: auto at this point
      // which breaks position: sticky for us, but is not reproducible in
      // a simple layout so it is limited to us
      //
      // visible is the browser default value for `overflow`
      if (typeof document !== 'undefined') {
        document.body.style.overflow = 'visible';
      }
    }
  } else if (getNodeEnv() === 'development') {
    throw new Error(
      'toggleScrollLock was called without a replacement scrollable target'
    );
  }
  isScrollLocked = shouldLock;
};

// Removes whitespace from object values. Only for String values, to preserve nested objects and numbers
export const removeWhitespaceFromValues = data => {
  return Object.keys(data).reduce((map, key) => {
    map[key] = isString(data[key]) ? trim(data[key]) : data[key];
    return map;
  }, {});
};

export const base64DecodeUnicode = str => {
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(c => {
        const tail = `00${c.charCodeAt(0).toString(16)}`.slice(-2);
        return `%${tail}`;
      })
      .join('')
  );
};

export const dateStringToDate = dateString => {
  // TODO use react-intl to account for international date formats.
  let [year, month, day] = dateString.split('-');
  year = parseInt(year, 10);
  month = parseInt(month, 10) - 1;
  day = parseInt(day, 10);

  return new Date(year, month, day);
};

export const dateStringToShortDateString = dateString => {
  const date = dateStringToDate(dateString);

  const month = date.getMonth() + 1;
  const monthStr = month < 10 ? `0${month}` : month.toString();

  const day = date.getUTCDate();
  const dayStr = day < 10 ? `0${day}` : day.toString();

  return [date.getFullYear(), monthStr, dayStr].join('-');
};

export const toggleStickyHeader = (el, headerHeight, scrollTargetTop) => {
  const offset = el.offsetTop;
  if (scrollTargetTop > headerHeight) {
    // headers scroll with page
    el.classList.remove('sticky');
  } else if (
    typeof window !== 'undefined' &&
    window.pageYOffset + headerHeight > offset
  ) {
    // headers stick to the top once the ad scrolls off the page
    el.classList.add('sticky');
  }
};

export const buildMeta = ({
  error = '',
  isLoading = false,
  receivedAt = Date.now(),
} = {}) => ({
  error,
  isLoading,
  receivedAt,
});

export const getIterableLocale = () => {
  var userLanguage = (
    (typeof document !== 'undefined' && document.documentElement.lang) ||
    navigator.language
  ).toLowerCase();
  switch (userLanguage) {
    case 'en-us':
      // default language in iterable is en, also for US
      return 'en';
    case 'en-gb':
      // locale in iterable was set to en-uk instead of en-gb
      return 'en-uk';
    default:
      return userLanguage;
  }
};

/**
 * @param {number} num       The number you wish to round up.
 * @param {number} multiple  The multiple you want your number rounded up to.
 */
export const roundUpToClosestMultiple = (num, multiple) => {
  return Math.ceil(num / multiple) * multiple;
};

/**
 * @param {string} dateOfBirth The date of birth, i.e. "2018-23-09"
 */
export const age = dateOfBirth => LocalizedDayjs().diff(dateOfBirth, 'years');

/**
 * @param {object} params The object you wish to convert to query params
 */
export const objectToQueryString = params => {
  return Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
};

export class Stopwatch {
  totalTrackedTime = 0;
  currentTrackedTime = null;

  start() {
    if (!this.currentTrackedTime) {
      this.currentTrackedTime = Date.now();
    }
  }

  stop() {
    if (this.currentTrackedTime) {
      this.totalTrackedTime += Date.now() - this.currentTrackedTime;
      this.currentTrackedTime = null;
    }
    return this.totalTrackedTime;
  }

  getTime() {
    if (this.currentTrackedTime) {
      this.totalTrackedTime += Date.now() - this.currentTrackedTime;
      this.currentTrackedTime = Date.now();
    }
    return this.totalTrackedTime;
  }

  reset() {
    this.totalTrackedTime = 0;
    this.currentTrackedTime = null;
  }
}

export const smoothScrollTo = (elementOrSelector, options = {}) => {
  const element =
    typeof elementOrSelector === 'string'
      ? document.querySelector(elementOrSelector)
      : elementOrSelector;

  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      ...options,
    });
  }
};

/**
 * @param {string} message The translation message string with placeholders
 * @param {object} values The placeholder values that must be replaced on the string
 * @return {string} The formatted string with replaced placeholders
 */
export const messageFormat = (message, values = {}) => {
  const msg = new IntlMessageFormat(message, global.translations.locale);
  return msg.format(values);
};

export const canUseWebP = () => {
  const elem = document.createElement('canvas');

  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }

  return false;
};
