import { useEffect } from 'react'

import { paths } from '@viewlio/config/src/viewlioConfig/paths'
import { useRouter } from 'next/router'

import { datadogLogger } from 'lib/datadog'
import { useGlobalStore } from 'stores'

export const useRedirects = ({
  signedInOnly,
  skipPhoneVerification,
  skipAgeVerification,
}: {
  signedInOnly: boolean
  skipAgeVerification: boolean
  skipPhoneVerification: boolean
}) => {
  const {
    shopper,
  } = useGlobalStore()

  const router = useRouter()

  const isShopperLoaded = shopper.meta.loaded
  const isShopperSignedIn = shopper.signedIn
  const isShopperAgeVerified = !shopper?.user?.redirectToAv
  const isShopperPhoneVerified = !shopper?.user?.requiresPhoneVerification

  const shouldRedirectToSignin = isShopperLoaded &&
    !isShopperSignedIn &&
    signedInOnly
  const shouldRedirectToPhoneVerification = isShopperLoaded &&
    isShopperSignedIn &&
    !isShopperPhoneVerified &&
    !skipPhoneVerification
  const shouldRedirectToAgeVerification = isShopperLoaded &&
    isShopperSignedIn &&
    !isShopperAgeVerified &&
    !skipAgeVerification

  useEffect(() => {
    if (shouldRedirectToSignin) {
      // This block shouldn't run. If it does, it means cloudfront account
      // redirects are not working for all cases. Looking into the remaining
      // cases it appears they are almost all edge-cases, hence these are logged
      // in datadog
      datadogLogger.info('Fallback client-side account redirect happening')
      router.push(paths.signin)
    } else if (shouldRedirectToPhoneVerification) {
      window.location.replace(paths.phoneVerification)
    } else if (shouldRedirectToAgeVerification) {
      window.location.replace(paths.ageVerification)
    }
  }, [shopper])

  const shouldRedirect = shouldRedirectToSignin ||
    shouldRedirectToPhoneVerification ||
    shouldRedirectToAgeVerification

  // if page requires signed in user, we should wait with rendering any content
  // until user is loaded and we are sure no redirect will happen.
  //
  // If page does not require signed in user, redirect to phone/age verification
  // may still happen if user is signed in with account which requires it.
  //
  // However in that case we should render immediately without waiting,
  // otherwise we would have loading splash flash before every page, which
  // would look bad.
  const shouldRenderLoadingSplash = signedInOnly &&
    (!isShopperLoaded || (isShopperLoaded && shouldRedirect))

  return {
    shouldRenderLoadingSplash,
  }
}
