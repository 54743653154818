import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import { ValidateCommitmentInstallmentLimitRequest } from '@viewlio/types/src/api/juulio'
import { CartLineItem } from '@viewlio/types/src/ecommerce'
import debounce from 'lodash/fp/debounce'

import {
  getCart,
  updateCart,
  validateCommitmentInstallmentLimit as validateCommitmentInstallmentLimitApi,
  deleteFutureSubscriptionAddOns as deleteFutureSubscriptionAddOnsApi,
} from 'lib/juulio/api'
import { segmentTrack } from 'lib/segment'
import { useGlobalStore } from 'stores/globalStore'
import { selectCurrentProductCatalogue } from 'stores/globalStore.selectors'
import { trackProductAdded, trackProductRemoved } from 'utils/analytics'
import { getErrorMeta, getLoadingMeta, getSuccessMeta } from 'utils/api'

import {
  addFlashMessage,
  flushFlashMessages,
} from '../uiStore'

import { useCartStore } from './cartStore'

const { getState, setState } = useCartStore

const cartItemCount = (lineItems) =>
  lineItems
    .map(lineItem => lineItem.quantity)
    .reduce((total, lineItemQty) => (
      total + lineItemQty
    ), 0)

export const debouncedCartUpdate = debounce(
  viewlioConfig.timers.cartApiDebounceMs,
  async ({
    args,
    callback,
    onFailure,
  }: {
    args: {
      futureSubscriptionAddOns?: null
      lineItems?: CartLineItem[]
      membershipOrder?: boolean
    }
    callback: () => void
    onFailure: () => void
  }) => {
    try {
      const cart = await updateCart(args)

      document.dispatchEvent(
        new CustomEvent('cart-quantity-updated', { detail: { quantity: cart.itemCount } }),
      )
      setState({
        cart,
        meta: getSuccessMeta(),
      })
      setCartItemCount(cart.itemCount)
    } catch ({ error }) {
      setState({ meta: getErrorMeta(error) })
      onFailure()
    } finally {
      !getState().meta.error && setState({ meta: getSuccessMeta() })
      callback()
    }
  },
)

export const fetchCart = async () => {
  const response = await getCart()
  const {
    cart,
    activeSubscriptionModalData,
    promotionFlashes,
    subscriptionPlan,
    errorFlash,
  } = response

  if (cart) {
    setState({
      activeSubscriptionModalData,
      cart,
      promotionFlashes,
      subscriptionPlan,
    })

    if (errorFlash) {
      addFlashMessage({
        content: errorFlash.content,
        type: errorFlash.type,
      })
    }

    if (cart?.lineItems) {
      setCartItemCount(cartItemCount(cart.lineItems))
    }
  }
}

export const updateCartSubscription = async (membershipOrder: boolean) => {
  const previousMembershipOrder = getState().cart.membershipOrder

  // optimistic update
  setState({
    cart: {
      ...getState().cart,
      membershipOrder,
    },
    meta: getLoadingMeta(),
  })

  await debouncedCartUpdate({
    args: { membershipOrder },
    callback: () => {
      segmentTrack(
        membershipOrder ? 'Auto-Ship Enabled' : 'Auto-Ship Disabled',
        { eventLocation: 'Cart Upsell Banner' },
      )
    },
    onFailure: () => {
      // rollback optimistic update
      setState({
        cart: {
          ...getState().cart,
          membershipOrder: previousMembershipOrder,
        },
      })
    },
  })
}

const trackChange = (lineItem: CartLineItem, quantityDiff: number) => {
  const trackingFn = quantityDiff > 0
    ? trackProductAdded
    : trackProductRemoved

  trackingFn(lineItem, Math.abs(quantityDiff))
}

export const updateLineItem = async (
  lineItem: CartLineItem,
  quantity: number,
) => {
  const { sku, offeringId } = lineItem

  const previousLineItems = getState().cart.lineItems
  const currentLineItem = previousLineItems.find(
    i => i.sku === sku && i.offeringId === offeringId,
  )
  const otherLineItems = previousLineItems.filter(
    i => i.sku !== sku || i.offeringId !== offeringId,
  )

  const quantityInCart = currentLineItem?.quantity || 0
  const quantityDiff = quantity - quantityInCart

  const newLineItem = quantityInCart ?
    { ...currentLineItem, quantity }
    : lineItem

  const lineItems = [
    ...otherLineItems,
    newLineItem,
  ]

  const lineItemsWithQuantity = lineItems.filter(
    lineItem => Boolean(lineItem.quantity),
  )

  // optimistic update
  setState({
    cart: {
      ...getState().cart,
      lineItems: lineItemsWithQuantity,
    },
    meta: getLoadingMeta(),
  })

  await debouncedCartUpdate({
    args: {
      lineItems: lineItemsWithQuantity,
    },
    callback: () => {
      if (quantityDiff !== 0) {
        trackChange(newLineItem, quantityDiff)
      }
    },
    onFailure: () => {
      // rollback optimistic update
      setState({
        cart: {
          ...getState().cart,
          lineItems: previousLineItems,
        },
      })
    },
  })
}

export const deleteFutureSubscriptionAddOns = async () => {
  try {
    const cart = await deleteFutureSubscriptionAddOnsApi()

    if (cart) {
      setState({
        cart,
        meta: getSuccessMeta(),
      })
    }
  } catch ({ error }) {
    if (error) {
      addFlashMessage({
        content: error,
        type: 'error',
      })
    } else {
      addFlashMessage({
        translationId: 'common.errors.default',
        type: 'error',
      })
    }
  }
}

export const validateCommitmentInstallmentLimit = async (
  lineItems: ValidateCommitmentInstallmentLimitRequest['lineItems'],
) => {
  flushFlashMessages()
  const { productCatalogue, selectedState } = useGlobalStore.getState()
  const currentProductCatalogue = selectCurrentProductCatalogue({
    productCatalogue,
    selectedState,
  })

  const nonGiftCardCommitmentRewardLineItems = lineItems.filter(lineItem => {
    const variant = currentProductCatalogue[lineItem.sku]
    const offering = variant.offerings?.find(
      offering => offering.id === lineItem.offeringId,
    )
    return variant.category !== 'rewards' && Boolean(offering?.commitmentReward)
  })

  setState({ blockCheckout: false })
  try {
    await validateCommitmentInstallmentLimitApi({
      lineItems: nonGiftCardCommitmentRewardLineItems.map(li => ({
        offeringId: li.offeringId,
        quantity: li.quantity,
        sku: li.sku,
      })),
    })
  } catch ({ error }) {
    if (error) {
      addFlashMessage({
        content: error,
        type: 'error',
      })
      setState({ blockCheckout: true })
    } else {
      // If API fails, safer not to block checkout. Especially as its not
      // absolutely critical to block
      addFlashMessage({
        translationId: 'common.errors.default',
        type: 'error',
      })
    }
  }
}

export const setCartItemCount = (count: number) =>
  setState({
    cartItemCount: count,
  })
