import { VIEWLIO_ENV } from '@viewlio/config/src/viewlioConfig'
import {
  RobotsIndexationOption,
  TranslatedValueOrDefaultKey,
} from '@viewlio/types/src'
import { MetaEntry } from '@viewlio/types/src/contentful'

import { formatEntry } from 'lib/contentful/formatEntry'
import { ogTypePathMap } from 'utils/translations/ogTypePathMap'

const getRobotsIndexation = (noindex = null): RobotsIndexationOption => {
  if (VIEWLIO_ENV !== 'production') {
    return 'noindex'
  }
  return noindex ? 'noindex' : 'index'
}

const translatedValueOrDefaultKey = (
  translatedValue,
  defaultKey,
): TranslatedValueOrDefaultKey => {
  const translatedValueExists =
    typeof translatedValue === 'string' && translatedValue.trim().length >= 1

  return {
    translated: translatedValueExists,
    value: translatedValueExists ? translatedValue : defaultKey,
  }
}

export const getProps = (metaEntry: MetaEntry) => {
  const data = formatEntry(metaEntry)

  const {
    url,
    title,
    description,
    noindex,
    socialImage,
  } = data || {}

  const slug = url?.split('/').pop()

  return {
    description: translatedValueOrDefaultKey(description, 'site.defaults.description'),
    image: socialImage?.url || 'images/social/default.jpg',
    ogType: ogTypePathMap[slug] || 'site.defaults.og_type',
    robotsIndexation: getRobotsIndexation(noindex),
    title: translatedValueOrDefaultKey(title, 'site.defaults.title'),
  }
}
