import { NextRouter } from 'next/router'

const pathSliceLength = (router: NextRouter) =>
  Math.min.apply(Math, [
    router.asPath.indexOf('?') > 0 ? router.asPath.indexOf('?') : router.asPath.length,
    router.asPath.indexOf('#') > 0 ? router.asPath.indexOf('#') : router.asPath.length,
  ])

/**
 * Get a pathname/route as a string without query parameters as the
 * client would see in their address bar
 *
 * https://stackoverflow.com/a/67404995
 * @param router Router
 * @returns pathname without query or hash parameters
 */
export const getBrowserPathName = (router: NextRouter): string =>
  router.asPath.substring(0, pathSliceLength(router))
