import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import cookie from 'js-cookie'
import { create, StoreApi } from 'zustand'
import { combine } from 'zustand/middleware'

type AgeGateStatusStore = {
  isAgeGateVerified: boolean
}

export const ageGateStatusStoreInitialState = (): AgeGateStatusStore => ({
  isAgeGateVerified: typeof document !== 'undefined',
})

const ageGateStatusStoreActions = (set: StoreApi<AgeGateStatusStore>['setState']) => ({
  setEnablePartialDelivery: (enablePartialDelivery: boolean) => {
    set({
      isAgeGateVerified:
        cookie.get(viewlioConfig.cookies.ageGate) === 'true' &&
        (enablePartialDelivery
          ? Boolean(cookie.get(viewlioConfig.cookies.userDeliverability))
          : true),
    })
  },
})

export const useAgeGateStatusStore = create(
  combine(ageGateStatusStoreInitialState(), ageGateStatusStoreActions),
)
