import { LoadingMeta } from '@viewlio/types/src'
import { ActiveSubscriptionModalData, CartWithCartLineItems, SubscriptionPlan } from '@viewlio/types/src/ecommerce'
import { FlashMessageTypes } from '@viewlio/types/src/FlashMessage'
import { create } from 'zustand'

import { initialLoadingMeta } from 'utils/api'

export type CartStore = {
  activeSubscriptionModalData?: ActiveSubscriptionModalData
  blockCheckout: boolean
  cart?: CartWithCartLineItems
  cartItemCount?: number
  meta: LoadingMeta
  promotionFlashes: Partial<Record<FlashMessageTypes, string>>
  subscriptionPlan?: SubscriptionPlan
}

export const cartStoreInitialState = (): CartStore => ({
  activeSubscriptionModalData: null,
  blockCheckout: false,
  cart: null,
  cartItemCount: 0,
  meta: initialLoadingMeta,
  promotionFlashes: null,
  subscriptionPlan: null,
})

export const useCartStore = create(cartStoreInitialState)
