import React from 'react'

type Props = Record<string, any> & {
  type: string
}

export const build = (type: string, data: Record<string, any>) => ({
  '@type': type,
  ...data,
})

export const StructuredData : React.FC<Props> = ({
  type,
  ...data
}) => (
  <script
    dangerouslySetInnerHTML={{
      __html: JSON.stringify({
        '@context': 'https://www.schema.org',
        ...build(type, data),
      }),
    }}
    type='application/ld+json'
  />
)
