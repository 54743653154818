import { RegisteredProduct } from '@viewlio/types/src/api/juulio'

import { GlobalStore } from './globalStore'

export const storeLocalesSelector = (store: GlobalStore) => {
  const {
    locale,
    juulioStore: {
      availableLocales,
      smallFlagUrl,
    },
  } = store

  return {
    current: locale,
    currentBase: locale?.split('-')[0],
    currentImage: smallFlagUrl,
    display: availableLocales.length > 1,
    locales: availableLocales,
  }
}

export const selectCurrentProductCatalogue = ({
  selectedState,
  productCatalogue,
}: Pick<GlobalStore, 'selectedState' | 'productCatalogue'>) => (
  productCatalogue?.statesOverrides?.[selectedState]
  ?? productCatalogue?.defaultCatalog
)

export const hasWarrantyFlowForProductType = (
  productType: RegisteredProduct['productType'],
) => ({
  juulioStore: {
    preferences: {
      warrantyFlowAccessoryEnabled,
      warrantyFlowDeviceEnabled,
      warrantyFlowPodEnabled,
    },
  },
}) => warrantyFlowAccessoryEnabled && productType === 'accessory'
    || warrantyFlowDeviceEnabled && productType === 'device'
    || warrantyFlowPodEnabled && productType === 'pod'
