import React from 'react'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { deployedEnvironments, viewlioConfig, VIEWLIO_ENV } from '@viewlio/config/src/viewlioConfig'

const { apiKeys, gitSHA } = viewlioConfig

export const bugsnagClient = Bugsnag.start({
  apiKey: apiKeys.bugsnag,
  appVersion: gitSHA,
  enabledReleaseStages: deployedEnvironments,
  logger: null,
  onError: (event) => {
    const { breadcrumbs, context, originalError } = event
    const { message } = originalError

    const reactError = message.match(/Minified React error #(?<code>.*);/)
    const chunkError = message.match(/Loading chunk \d+ failed/)

    if (chunkError) {
      const knownError = breadcrumbs.some(breadcrumb => breadcrumb.message.includes('loadable-components'))

      event.errors[0].errorClass = 'ReactChunkError'
      event.severity = knownError ? 'warning' : event.severity
      event.unhandled = !knownError

      event.addMetadata('Chunk Error', {
        chunkError: true,
      })
    }

    if (reactError) {
      const { code } = reactError.groups

      const knownHydrationError = ['423', '418'].includes(code)
      const knownHydrationErrorPath = /(signin|age-gate|shop)/.test(context)
      const shouldWarn = knownHydrationError && knownHydrationErrorPath

      event.errors[0].errorClass = 'ReactMinifiedError'
      event.severity = shouldWarn ? 'warning' : event.severity
      event.unhandled = !shouldWarn

      event.addMetadata('React Minified Error', {
        errorCode: reactError.groups?.code,
        knownHydrationError,
      })
    }

    if (window?.FS?.getCurrentSessionURL) {
      event.addMetadata('fullstory', {
        urlAtTime: window.FS.getCurrentSessionURL(true),
      })
    }
  },
  plugins: [new BugsnagPluginReact()],
  releaseStage: VIEWLIO_ENV,
})

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
