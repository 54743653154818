import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import { SuggestedStore } from '@viewlio/types/src'
import cookie from 'js-cookie'

export const showCountrySelector = (
  asPath: string,
  suggestedStore?: SuggestedStore,
) => {
  if (asPath.startsWith('/geofence') ||
    asPath.startsWith('/georestriction') ||
    cookie.get(viewlioConfig.cookies.countrySelector)) {
    return false
  }

  if (suggestedStore?.countryIso) {
    return true
  }

  return false
}
