import { CartLineItem, Variant } from '@viewlio/types/src/ecommerce'

import { segmentTrack } from 'lib/segment'
import { useCartStore } from 'stores'
import { decamelizeKeys } from 'utils/caseConversion'

// Core Ordering Events.
// See: https://segment.com/docs/connections/spec/ecommerce/v2/#core-ordering
enum Events {
  PodAddedFromShop = 'Pod Direct Added From Shop PLP',
  ProductAdded = 'Product Added',
  ProductRemoved = 'Product Removed'
}

// 'Product Added' or 'Product Removed' events.
// https://segment.com/docs/connections/spec/ecommerce/v2/#product-added
// https://segment.com/docs/connections/spec/ecommerce/v2/#product-removed
const trackProductChange = (event: string) => (
  lineItem: CartLineItem | Variant,
  quantity: number,
) => {
  const { cart } = useCartStore.getState()

  const lineItemProperties = (lineItem) => ({
    imageUrl: lineItem.imageUrl,
    name: lineItem.name,
    price: lineItem.price,
    productId: lineItem.sku,
    quantity: lineItem.quantity,
    sku: lineItem.sku,
  })

  // 'products' is an array needed by the Iterable destination and
  // it is used to update the user's shopping cart contents.
  // eslint-disable-next-line max-len
  // https://segment.com/docs/connections/destinations/catalog/iterable/#ecommerce
  const properties = {
    ...lineItemProperties(lineItem),
    path: window?.location?.pathname,
    products: cart?.lineItems?.map(lineItemProperties),
    quantity,
  }

  segmentTrack(event, decamelizeKeys(properties))
}

export const trackProductAdded = trackProductChange(Events.ProductAdded)
export const trackProductRemoved = trackProductChange(Events.ProductRemoved)
export const trackPodAddedFromShop = trackProductChange(Events.PodAddedFromShop)
