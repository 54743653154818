import React, { useState, useEffect } from 'react'

import { TranslatedValueOrDefaultKey } from '@viewlio/types/src'
import { MetaEntry } from '@viewlio/types/src/contentful'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { IntlShape, useIntl } from 'react-intl'

import { StructuredData } from 'components/common/seo'
import { initialize } from 'lib/segment'
import { useGlobalStore } from 'stores'
import { domainFromStore } from 'utils/routing/domainFromStore'
import { getBrowserPathName } from 'utils/routing/getBrowserPathName'
import { getViewlioPath } from 'utils/routing/getViewlioPath'
import { removeTrailingSlash } from 'utils/routing/removeTrailingSlash'

import { getProps } from './AppHead.serializer'

type Props = {
  alternateLangs?: Record<string, string>
  contentfulData: MetaEntry
}

export const translateIfNecessary =
  ({ value, translated }: TranslatedValueOrDefaultKey, intl: IntlShape) =>
    translated ? value : intl.formatMessage({ id: value })

export const AppHead: React.FC<Props> = ({
  alternateLangs = {},
  contentfulData,
}) => {
  const {
    title,
    robotsIndexation,
    description,
    image,
    ogType,
  } = getProps(contentfulData)

  const [isSegmentInitialized, setIsSegmentInitialized] = useState(false)

  const {
    locale,
    shopper: {
      user, geoLocation, anonymousId,
    },
    juulioStore,
  } = useGlobalStore()

  const router = useRouter()
  const intl = useIntl()
  const browserPathName = getBrowserPathName(router)
  const domain = domainFromStore(juulioStore?.code)
  const canonicalUrl = `${domain}${getViewlioPath(locale, browserPathName)}`
  const ogUrl = removeTrailingSlash(canonicalUrl)

  useEffect(() => {
    const forgetIterableUsers = juulioStore?.preferences?.forgetIterableUsers
    const segmentWriteKey = juulioStore?.marketingConfigs?.segmentWriteKey

    // As long as we have a segment write key and geolocation, we can initialise
    // segment regardless of logged-in status of a user
    const shouldInitializeSegment =
      segmentWriteKey && geoLocation && !isSegmentInitialized

    if (shouldInitializeSegment) {
      setIsSegmentInitialized(true)

      initialize({
        anonymousId,
        forgetIterableUsers,
        geoLocation,
        key: segmentWriteKey,
        shouldUseCookiebot: juulioStore?.preferences?.showCookiebot ?? false,
        user,
      })
    }
  }, [geoLocation])

  const robotsIndexTag = () => {
    if (browserPathName === '/age-gate') {
      return 'noindex'
    }

    return robotsIndexation
  }

  const robotsRules = [
    'noarchive',
    robotsIndexTag(),
  ]

  const robotsMetaTag = <meta name='robots' content={robotsRules.join(', ')} />

  const logoFaviconLocales = [
    'en-GB',
    'en-CA',
    'fr-CA',
  ]
  const faviconAssetPath = logoFaviconLocales.includes(locale)
    ? '/assets/viewlio/favicons/logo'
    : '/assets/viewlio/favicons'

  return (
    <>
      <Head>
        {/* Font */}
        <link rel='preload' href='/assets/viewlio/fonts/dogpatch-latin.woff2' as='font' type='font/woff2' crossOrigin='anonymous' />
        {/* End Font */}

        {/* Preconnects */}
        <link rel='preconnect' href='https://images.ctfassets.net' />
        <link rel='preconnect' href='https://assets.juul.com' />
        <link rel='preconnect' href='https://sessions.bugsnag.com' />
        <link rel='preconnect' href='https://api.segment.io' />
        {/* End Preconnects */}

        {/* Title and Metas */}
        {title && (
          <>
            <title>{translateIfNecessary(title, intl)}</title>
            <meta property='og:title' content={translateIfNecessary(title, intl)} />
          </>
        )}
        {robotsMetaTag}
        {description && (
          <>
            <meta name='description' content={translateIfNecessary(description, intl)} />
            <meta property='og:description' content={translateIfNecessary(description, intl)} />
          </>
        )}
        {ogType && (<meta property='og:type' content={intl.formatMessage({ id: ogType })} />)}
        {image && (<meta property='og:image' content={image} />)}
        <meta
          name='viewport'
          content='width=device-width,minimum-scale=1.0,maximum-scale=5.0'
        />
        <meta property='og:url' content={ogUrl} />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:site' content='@JUULvapor' />
        {/* End Title and Metas */}

        {/* Favicon */}
        <link rel='icon' href={`${faviconAssetPath}/favicon.ico`} />
        <link rel='apple-touch-icon' sizes='180x180' href={`${faviconAssetPath}/apple-touch-icon.png`} />
        <link rel='apple-touch-icon' sizes='57x57' href={`${faviconAssetPath}/apple-touch-icon-57x57.png`} />
        <link rel='apple-touch-icon' sizes='76x76' href={`${faviconAssetPath}/apple-touch-icon-76x76.png`} />
        <link rel='apple-touch-icon' sizes='120x120' href={`${faviconAssetPath}/apple-touch-icon-120x120.png`} />
        <link rel='apple-touch-icon' sizes='152x152' href={`${faviconAssetPath}/apple-touch-icon-152x152.png`} />
        <link rel='apple-touch-icon' sizes='180x180' href={`${faviconAssetPath}/apple-touch-icon-180x180.png`} />
        <link rel='icon' type='image/png' sizes='32x32' href={`${faviconAssetPath}/favicon-32x32.png`} />
        <link rel='icon' type='image/png' sizes='192x192' href={`${faviconAssetPath}/android-chrome-192x192.png`} />
        <link rel='icon' type='image/png' sizes='16x16' href={`${faviconAssetPath}/favicon-16x16.png`} />
        <link rel='manifest' href={`${faviconAssetPath}/manifest.json`} crossOrigin='use-credentials' />
        <link rel='mask-icon' href={`${faviconAssetPath}/safari-pinned-tab.svg`} color='#482c2f' />
        <link rel='shortcut icon' href={`${faviconAssetPath}/favicon.ico`} />
        <meta name='application-name' content='JUUL' />
        <meta name='apple-mobile-web-app-title' content='JUUL' />
        <meta name='msapplication-TileColor' content='#ffffff' />
        <meta name='theme-color' content='#ffffff' />
        {/* End Favicon */}

        {/* Href langs */}
        {Object.keys(alternateLangs).map(locale => (
          <link
            key={`href-lang-${locale}`}
            rel='alternate'
            href={alternateLangs[locale]}
            hrefLang={locale}
          />
        ),
        )}
        <link rel='canonical' href={canonicalUrl} />
        {/* End href langs */}

        {/* Schema.org Structured Data */}
        <StructuredData
          type='Organization'
          url={juulioStore?.fullUrl}
          logo={`${juulioStore?.fullUrl}/images/juul_logo.jpg`}
        />
        {/* End Schema.org Structured Data */}
      </Head>
    </>
  )
}
