export const ogTypePathMap = {
  'basic-kit': 'site.devices.basic_kit.og_type',
  charger_usb: 'site.accessories.charger_usb.og_type',
  'classic-menthol': 'site.pods.classic_menthol.og_type',
  'classic-tobacco': 'site.pods.classic_tobacco.og_type',
  'classic-tobacco-5-percent': 'site.pods.classic_tobacco_5_percent.og_type',
  'cool-cucumber': 'site.pods.cool_cucumber.og_type',
  'cool-mint': 'site.pods.cool_mint.og_type',
  'creme-5-percent': 'site.pods.creme_5_percent.og_type',
  'creme-brulee': 'site.pods.creme_brulee.og_type',
  'cucumber-5-percent': 'site.pods.cucumber_5_percent.og_type',
  devices: 'site.devices.og_type',
  'fruit-5-percent': 'site.pods.fruit_5_percent.og_type',
  'fruit-medley': 'site.pods.fruit_medley.og_type',
  mango: 'site.pods.mango.og_type',
  'mango-5-percent': 'site.pods.mango_5_percent.og_type',
  'menthol-5-percent': 'site.pods.menthol_5_percent.og_type',
  'mint-3-percent': 'site.pods.mint_3_percent.og_type',
  'mint-5-percent': 'site.pods.mint_5_percent.og_type',
  multipack: 'site.pods.multipack.og_type',
  'multipack-5-percent': 'site.pods.multipack_5_percent.og_type',
  'navy-blue-kit': 'site.devices.navy_blue_kit.og_type',
  pods: 'site.pods.og_type',
  'starter-kit': 'site.devices.starter_kit.og_type',
  'usb-charging-dock': 'site.accessories.usb_charging_dock.og_type',
  'virginia-tobacco-3-percent': 'site.pods.virginia_tobacco_3_percent.og_type',
  'virginia-tobacco-5-percent': 'site.pods.virginia_tobacco_5_percent.og_type',
}
