import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import cookie from 'js-cookie'
import { create, StoreApi } from 'zustand'
import { combine } from 'zustand/middleware'

import { bugsnagClient } from 'lib/bugsnag'
import {
  fetchDeliveryAddress as fetchDeliveryAddressApi,
  setDeliveryAddress as setDeliveryAddressApi,
} from 'lib/juulio/api'
import { useGlobalStore } from 'stores/globalStore'

export type UserDeliverabilityStoreState = {
  address?: string
  city: string
  deliverable: boolean
  sameday: boolean
  state: string
  zipcode: string
}

export type UserDeliverabilityStoreActions = {
  fetchFullAddress(expiresDays?: number): void
  setFullAddress(
    address:string, city: string, zipcode: string, expiresDays?: number
  ): void
}

export const userDeliverabilityStoreInitialState:
  UserDeliverabilityStoreState = {
    address: null,
    city: null,
    deliverable: null,
    sameday: null,
    state: null,
    zipcode: null,
  }

const userDeliverabilityStoreActions = (
  set: StoreApi<UserDeliverabilityStoreState>['setState'],
  get: StoreApi<UserDeliverabilityStoreState>['getState'],
): UserDeliverabilityStoreActions => {
  const getDataFromUserDeliverabilityCookie = ()
    : UserDeliverabilityStoreState => {
    const userDeliverabilityCookie = cookie.get(
      viewlioConfig.cookies.userDeliverability,
    )
    if (!userDeliverabilityCookie) {
      return userDeliverabilityStoreInitialState
    }

    return JSON.parse(userDeliverabilityCookie, (_key, value) => {
      if (typeof value === 'string') {
        return decodeURI(value)
      }
      return value
    })
  }

  const setUserDeliverabilityCookie = (
    response: UserDeliverabilityStoreState, expiresDays?: number,
  ) => {
    cookie.set(
      viewlioConfig.cookies.userDeliverability,
      JSON.stringify({
        address: response.address,
        city: response.city,
        deliverable: response.deliverable,
        sameday: response.sameday,
        state: response.state,
        zipcode: response.zipcode,
      }),
      { expires: expiresDays },
    )
  }

  const setData = (
    data: UserDeliverabilityStoreState,
  ) => set({
    address: data.address,
    city: data.city,
    deliverable: typeof data.deliverable === 'boolean' ? data.deliverable : null,
    sameday: typeof data.sameday === 'boolean' ? data.sameday : null,
    state: data.state,
    zipcode: data.zipcode,
  })

  const refreshDataFromCookies = () => setData({
    ...getDataFromUserDeliverabilityCookie(),
  })

  const fetchAndSetAddress = async (expiresDays?: number) => {
    try {
      const response = await fetchDeliveryAddressApi()

      const { data: { attributes } } = response
      attributes.state && useGlobalStore.setState(
        { selectedState: attributes.state },
      )

      setData(attributes)
      setUserDeliverabilityCookie(attributes, expiresDays)
    } catch (error) {
      if (error?.statusCode !== 404) {
        bugsnagClient?.notify(error.error)
      }
    }
  }

  return {
    fetchFullAddress: (expiresDays?: number) => {
      refreshDataFromCookies()

      const { city, state, zipcode } = get()

      if (zipcode && city && state) {
        return
      } else {
        fetchAndSetAddress(expiresDays)
      }
    },
    setFullAddress: async (
      address: string,
      city: string,
      zipcode: string,
      expiresDays,
    ) => {
      try {
        const response = await setDeliveryAddressApi({ address, city, zipcode })

        const { data: { attributes } } = response
        attributes.state && useGlobalStore.setState(
          { selectedState: attributes.state },
        )

        setData(attributes)
        setUserDeliverabilityCookie(attributes, expiresDays)
      } catch (error) {
        bugsnagClient?.notify(error.error)
      }
    },
  }
}

export const useUserDeliverabilityStore = create(
  combine(
    userDeliverabilityStoreInitialState,
    userDeliverabilityStoreActions,
  ),
)
