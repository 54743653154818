import { useEffect } from 'react'

import { SessionFlashMessage } from '@viewlio/types/src/api/juulio/AccountRecoveryVerifyCodeResponse'
import { FlashMessage } from '@viewlio/types/src/FlashMessage'

import { addFlashMessage } from 'stores/uiStore'

export const FLASH_MESSAGE_KEY = 'flashMessage'

const getSessionFlashMessage = (): FlashMessage => {
  const sessionflash = sessionStorage.getItem(FLASH_MESSAGE_KEY)
  if (sessionflash) {
    const { content, type } = JSON.parse(sessionflash)
    return {
      content,
      type,
    }
  }
}

export const setSessionFlashMessage = (message: SessionFlashMessage) => {
  sessionStorage.setItem(FLASH_MESSAGE_KEY, JSON.stringify(message))
}

export const useSessionStorageFlashMessages = () => {
  useEffect(() => {
    const flashMessage = getSessionFlashMessage()
    if (flashMessage) {
      addFlashMessage(flashMessage)
      sessionStorage.removeItem(FLASH_MESSAGE_KEY)
    }
  }, [])

  return { setSessionFlashMessage }
}
