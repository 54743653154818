const waitForCookiebot = () => {
  if (window.Cookiebot?.loaded) {
    return Promise.resolve(window.Cookiebot)
  }
  return new Promise(rs =>
    window.addEventListener('CookiebotOnLoad', rs, { once: true }),
  ).then(() => window.Cookiebot)
}

export enum ConsentCategory {
  Marketing = 'marketing',
  Necessary = 'necessary',
  Statistics = 'statistics',
}

export type Consent = Record<ConsentCategory, boolean>

// A function that returns a promise that resolves to the Cookiebot preferences.
export const getConsent = async (): Promise<Consent> => {
  const cookieBot = await waitForCookiebot()
  if (!cookieBot) return Promise.resolve(null)

  // If the store does use Cookiebot,
  // but we've already captured the users preferences,
  // again lets return instantly with the captured preferences.
  if (cookieBot.hasResponse) return Promise.resolve(cookieBot.consent)

  // Finally, if the store does use Cookiebot and
  // we still haven't captured user preferences,
  // wait until we have and then return the captured preferences.
  return new Promise(rs => {
    window.addEventListener('CookiebotOnAccept', rs, { once: true })
    window.addEventListener('CookiebotOnDecline', rs, { once: true })
  }).then(() => cookieBot.consent)
}
